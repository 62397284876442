import React from "react";

import { Helmet } from "react-helmet";

import { useLocation } from "react-router-dom";
import Footer from "../components/footer";
import { Urls } from "../context/Urls";
import "./project-details.css";

const ProjectDetails = (props) => {
  const location = useLocation();
  const { companyData } = location.state;
  return (
    <div className="project-details-container mt-5 pt-5">
      <Helmet>
        <title>project-details - abamoman</title>
        <meta property="og:title" name="description" content="Get detailed information about specific projects handled by ABAM OMAN SPC, showcasing our expertise in electrical solutions." />
      </Helmet>
      {/* <Navbar rootClassName="navbar-root-class-name5"></Navbar> */}
      <div className="project-details-hero">
        <img
          alt="image"
          src={Urls.IMAGE_URL + companyData.images[0].link}
          className="project-details-image"
        />
        <div className="project-details-container1">
          <h1 className="project-details-text">{companyData.product_title}</h1>
          <h2 className="project-details-text01">{companyData.company_name}</h2>
          <span className="project-details-text02">
            <span>
              <span>{companyData.description}</span>
            </span>
          </span>
        </div>
      </div>
      <div className="project-details-features"></div>
      <Footer rootClassName="footer-root-class-name4"></Footer>
    </div>
  );
};

export default ProjectDetails;
