import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Nav, Modal, Button, Image, Form, Fade } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useAuth } from "../context/Context";
import { Urls } from "../context/Urls";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';

function CertificateUpload() {
  const { getCertificate, certificates, addCertificate } = useAuth();
  const [showView, setShowView] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selectedCert, setSelectedCert] = useState(null);
  const [newCert, setNewCert] = useState({ type: '', image: null });
  const [heading, setHeading] = useState("HSE Certificate");
  const [page, setPage] = useState('certificate');
  const [certToDelete, setCertToDelete] = useState(null);
  const [inProp, setInProp] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useHistory();

  useEffect(() => {
    setInProp(false)
    getCertificate(page);
    setTimeout(() => {
      setInProp(true);
    }, 500);
  }, [page]);

  const handleNavClick =  (value) => {
     setPage(value);
    console.log(`Nav.Link clicked: ${value}`);
    setTimeout(() => {
      if(value === 'certificate'){
        setHeading("HSE Certificate")
      }else if(value === 'policy'){
        setHeading("Policies");
      }
    }, 500);
   
  };

  const handleCloseView = () => setShowView(false);
  const handleShowView = (cert) => {
    setSelectedCert(cert);
    setShowView(true);
  };

  const handleCloseAdd = () => setShowAdd(false);
  const handleShowAdd = () => setShowAdd(true);

  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = async (cert) => {
    setCertToDelete(cert._id);
    setShowDelete(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewCert({ ...newCert, [name]: value });
  };

  const handleImageChange = (e) => {
    setNewCert({ ...newCert, image: e.target.files[0] });
  };

  const handleAddCertificate = async () => {
    setLoading(true);
    console.log('Adding certificate:'+newCert+"loading iss "+loading);
    const formData = new FormData();
    if(newCert.type==""){
      toast.error('Please select a type');
      setLoading(false)
      return;
    }
    formData.append('type', newCert.type);
    formData.append('image', newCert.image);
    try{
    await addCertificate(formData); // Assuming addCertificate accepts FormData
    setHeading("HSE Certificate");
    setLoading(false)
    handleCloseAdd();
    }catch(e){
      console.log("the error from add certificate",e);
      toast.error("error ocured")
  
    }finally{
      setLoading(false)
    }
  };

  const { user ,logout} = useAuth();

  const handleDeleteCertificate = async () => {
    console.log('Deleting certificate:', certToDelete);
    // Add logic to delete certificate
    setLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const data = await axios.post(
        Urls.BACKEND_URL + "/certificate/deleteCertificates/" + certToDelete,
        "",
        config
      );
      if (data.status === 200) {
        toast.success("deleted successfully");
      } else if (data.status === 401) {
        logout();
        navigate.push("/Admin/login");
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false)
    handleCloseDelete();
    setHeading("HSE Certificate");
    getCertificate('certificate');
  };

  return (
    <Container>
      <ToastContainer />
      <Nav variant="tabs">
        <Nav.Item>
          <Nav.Link style={{ color: "black" }} onClick={() => handleNavClick('certificate')}>HSE Certificate</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link style={{ color: "black" }} onClick={() => handleNavClick('policy')}>Policies</Nav.Link>
        </Nav.Item>
      </Nav>

      <Button className="mt-3" onClick={handleShowAdd}>Add Certificate</Button>

      <Fade in={inProp}>
        <div>
          <h2 className="mt-3 pb-3">{heading}</h2>
          <Row>
            {certificates.map((cert) => (
              <Col key={cert.id} xs={12} md={6} lg={3} className="mb-4">
                <Image
                  src={Urls.IMAGE_URL + cert.images}
                  alt="HSE Certificate"
                  thumbnail
                  onClick={() => handleShowView(cert)}
                  style={{ cursor: 'pointer' }}
                />
                <Button variant="danger" onClick={() => handleShowDelete(cert)}>Delete</Button>
              </Col>
            ))}
          </Row>
        </div>
      </Fade>

      <Modal show={showView} onHide={handleCloseView} centered>
        <Modal.Body>
          {selectedCert && <Image src={Urls.IMAGE_URL + selectedCert.images} alt={selectedCert} fluid />}
        </Modal.Body>
      </Modal>

      <Modal show={showAdd} onHide={handleCloseAdd} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Certificate</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formCertType">
              <Form.Label>Certificate Type</Form.Label>
              <Form.Control as="select" name="type" value={newCert.type} onChange={handleInputChange}>
                <option value="">Select type</option>
                <option value="certificate">HSE Certificate</option>
                <option value="policy">Policy</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formCertImage" className="mt-3">
              <Form.Label>Certificate Image</Form.Label>
              <Form.Control type="file" name="image" onChange={handleImageChange} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
        {loading?(<Spinner className="mx-3" animation="grow" />):(
          <div>
          <Button variant="secondary" onClick={handleCloseAdd}>Close</Button>
          <Button variant="primary" onClick={handleAddCertificate}>Add Certificate</Button>
          </div>)}
        </Modal.Footer>
      </Modal>

      <Modal show={showDelete} onHide={handleCloseDelete} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this certificate?
        </Modal.Body>
        <Modal.Footer>
        {loading?(<Spinner className="mx-3" animation="grow" />):(
          <div>
          <Button variant="secondary" onClick={handleCloseDelete}>Cancel</Button>
          <Button variant="danger" onClick={handleDeleteCertificate}>Delete</Button>
          </div>)}
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default CertificateUpload;
