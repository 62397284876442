import React from "react";
import { Container, Row, Col, Card, ListGroup } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import "./projects.css";
import { useAuth } from "../context/Context";
import { Urls } from "../context/Urls";
import { Link } from "react-router-dom";

function CompanyItem({ company }) {
  return (
    <Col lg={4} md={6} className="mb-4">
      <Card>
        <Card.Img
          variant="top"
          src={Urls.IMAGE_URL + company.images[0].link}
          alt={`${company.name} logo`}
        />
        <Card.Body>
          <Card.Title>{company.company_name}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            {company.product_title}
          </Card.Subtitle>
          <Link
            to={{
              pathname: `/project-details/${company._id}`,
              state: { companyData: company },
            }}
          >
            <Card.Link>Learn more...</Card.Link>
          </Link>
        </Card.Body>
      </Card>
    </Col>
  );
}

const CompaniesList = () => {
  const { project } = useAuth();
  return (
    <Row xs={1} md={2} lg={3} className="g-4">
      {project.length === 0 ? (
        <ListGroup.Item>
          <Card className="h-100">
            <Card.Img
              variant="top"
              src="https://png.pngtree.com/png-vector/20211111/ourmid/pngtree-minimal-loading-icon-graphic-png-image_4029241.png"
              alt="Loading"
            />
            <Card.Body>
              <Card.Title>Loading...</Card.Title>
            </Card.Body>
          </Card>
        </ListGroup.Item>
      ) : (
        project.map((company, index) => (
          <CompanyItem key={index} company={company} />
        ))
      )}
    </Row>
  );
};

const Projects = (props) => {
  return (
    <div className="projects-container">
      <Helmet>
        <title>Projects</title>
        <meta
          name="description"
          content="Explore the diverse range of projects undertaken by ABAM OMAN SPC in providing exceptional electrical solutions."
        />
        <link rel="canonical" href="/projects" />
      </Helmet>
      <section className="projects-news">
        <div className="projects-heading">
          <h2 className="projects-text">Projects</h2>
          <p className="projects-text1">List of all our completed projects</p>
        </div>
        <Container>
          <CompaniesList />
        </Container>
      </section>
      <Footer rootClassName="footer-root-class-name2"></Footer>
    </div>
  );
};

export default Projects;
