import React from "react";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import TestimonialCard2 from "../components/testimonial-card2";
import GalleryCard2 from "../components/gallery-card2";
import Book from "../components/book";
import Footer from "../components/footer";
import "./services.css";

const Services = (props) => {
  return (
    <div className="services-container">
      <Helmet>
        <title>Services</title>
        <meta
          name="description"
          content="ABAM OMAN SPC offers comprehensive electrical solutions tailored to your needs. Discover our range of services."
        />
        <link rel="canonical" href="/services" />
      </Helmet>
      <div className="services-hero">
        <span className="services-text">
          Electrical works offered by AL BADR AL MOUNIR ENGINEERING AND CONTRACTING SPC
        </span>
        <h1 className="services-text01">Electrical Works</h1>
        <a
          href="#book"
          style={{ textDecoration: "none" }}
          className="services-button button"
        >
          Contact Us
        </a>
      </div>
      <div className="services-testimonial">
        <div className="services-container01">
          <div className="services-container02">
            <div className="services-container03">
              <TestimonialCard2
                profileSrc="https://images.unsplash.com/photo-1614630982169-e89202c5e045?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDIwfHxtYWxlJTIwcG9ydHJhaXR8ZW58MHx8fHwxNjI2NDUyMTk4&amp;ixlib=rb-1.2.1&amp;h=1200"
                rootClassName="rootClassName1"
              ></TestimonialCard2>
            </div>
          </div>
        </div>
      </div>
      <div className="services-banner">
        <span className="services-text02">
          <span>
            Led by seasoned electrical engineers and contractors, we have
            quickly built a reputation for technical excellence and exceptional
            service. ABAM OMAN SPC is registered with the DCRP and relevant
            authorities to professionally handle electrical projects up to 11kV.
          </span>
          <br></br>
          <br></br>
          <br></br>
          <span>Our quality electrical offerings span:</span>
          <br></br>
          <br></br>
          <br></br>
          <span>-11kV Cable and Overhead Line Installation</span>
          <br></br>
          <br></br>
          <span>-Distribution Transformer and Switchgear Installation</span>
          <br></br>
          <br></br>
          <span>-Fault Level Calculation and Protection System Design</span>
          <br></br>
          <br></br>
          <span>
            -Testing, Commissioning, and Maintenance
            <span
              dangerouslySetInnerHTML={{
                __html: " ",
              }}
            />
          </span>
          <span>
            <span
              dangerouslySetInnerHTML={{
                __html: " ",
              }}
            />
          </span>
        </span>
      </div>
      <div className="services-gallery">
        <div className="services-container04">
          <div className="services-container05">
            <div className="services-container06">
              <GalleryCard2
                imageSrc="https://images.unsplash.com/photo-1510877619479-2569d3c6e18f?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDg3fHxFbGVjdHJpY2FsfGVufDB8fHx8MTcwNzMwMzczN3ww&amp;ixlib=rb-4.0.3&amp;h=1500"
                rootClassName="rootClassName2"
              ></GalleryCard2>
            </div>
            <div className="services-container07">
              <GalleryCard2
                imageSrc="https://images.unsplash.com/photo-1563068261-13ebbdf16aa3?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDV8fEVsZWN0cmljYWx8ZW58MHx8fHwxNzA3MzAzNjQyfDA&amp;ixlib=rb-4.0.3&amp;h=1500"
                rootClassName="rootClassName3"
              ></GalleryCard2>
            </div>
          </div>
          <div className="services-container08">
            <GalleryCard2
              imageSrc="https://images.unsplash.com/photo-1547893547-7c2ab2cc9689?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDYwfHxFbGVjdHJpY2FsfGVufDB8fHx8MTcwNzMwMzcwNHww&amp;ixlib=rb-4.0.3&amp;h=1500"
              rootClassName="rootClassName"
            ></GalleryCard2>
          </div>
        </div>
        <div className="services-container09">
          <GalleryCard2
            imageSrc="https://images.unsplash.com/photo-1473341304170-971dccb5ac1e?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDEwfHxFbGVjdHJpY2FsfGVufDB8fHx8MTcwNzMwMzY0Mnww&amp;ixlib=rb-4.0.3&amp;h=1500"
            rootClassName="rootClassName4"
          ></GalleryCard2>
        </div>
      </div>
      <div className="services-container10">
        <Book rootClassName="book-root-class-name"></Book>
      </div>
      <Footer rootClassName="footer-root-class-name"></Footer>
    </div>
  );
};

export default Services;
