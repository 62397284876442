import React from 'react'

import { Helmet } from 'react-helmet'
import Footer from "../components/footer";

import './career.css'

const Career = (props) => {
  const pageStyle = {
    fontFamily: "'Poppins', sans-serif",
  };
  return (
    <div style={pageStyle} className="career-container">
      <Helmet>
        <title>Career - abamoman</title>
        <meta property="og:title" name="description" content="Join ABAM OMAN SPC and be a part of a leading electrical solutions provider. Check out our current career opportunities." />
      </Helmet>
      <div class="career-container">
        <div class="hero2-header5 thq-section-padding">
          <img
            alt="image"
            src="https://images.unsplash.com/photo-1579783398506-cb73a55deb39?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDEwfHxPbWFufGVufDB8fHx8MTcxNzIyMzgwM3ww&amp;ixlib=rb-4.0.3&amp;w=1500"
            class="hero2-image"
          />
          <div class="hero2-container">
            <div class="hero2-max-width thq-section-max-width">
              <div class="hero2-column">
                <div class="hero2-content">
                  <h1 class="hero2-text thq-heading-1">
                    Join Our Team at ABAM OMAN SPC
                  </h1>
                  <p class="hero2-text1 thq-body-large">
                    Established in 2024, AL BADR AL MOUNIR ENGINEERING AND CONTRACTING SPC
                    (ABAM OMAN SPC) brings a dynamic, quality-focused approach to
                    delivering electrical services for commercial, industrial,
                    and government clients across Oman. Led by seasoned
                    electrical engineers and contractors, we have quickly built
                    a reputation for technical excellence and exceptional
                    service.
                  </p>
                  <div class="hero2-actions pb-3">
                    <div class="hero2-container1">
                    <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLScoINCUJfKjrl8uGJc99CnRSOGFKYPtJ5hkKgfRJX8rZvrhyQ/viewform?usp=sf_link"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                      <button class="hero2-button thq-button-filled">
                        <span class="hero2-text2 thq-body-small">
                          Upload Resume
                        </span>
                      </button>
                      </a>
                    </div>
                    <div class="hero2-container2">
                    <a
                  href="mailto:info@abamoman.com?subject=Career"

                >
                      <button class="hero2-button1 thq-button-outline">
                        <span class="hero2-text3 thq-body-small">Email Us</span>
                      </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="career-container1 thq-section-padding">
          <div class="career-max-width thq-section-max-width">
            <div class="career-content thq-flex-column">
              <ul class="thq-flex-column">
                <li class="thq-flex-column list-item">
                  <h2 class="career-heading1 thq-heading-2">
                    Why Work with Us?
                  </h2>
                  <p class="thq-body-small">
                    At ABAM OMAN SPC, we believe that our people are our greatest
                    asset. We foster a collaborative and innovative work
                    environment where every team member can thrive. Here’s why
                    you should consider joining our team:
                  </p>
                </li>
                <li class="list-item">
                  <h3 class="career-heading2 thq-heading-3">
                    Technical Excellence:
                  </h3>
                  <p class="thq-body-small">
                    We pride ourselves on delivering high-quality electrical
                    solutions. As part of our team, you’ll have the opportunity
                    to work on challenging projects and contribute to
                    cutting-edge solutions.
                  </p>
                </li>
                <li class="list-item">
                  <h3 class="career-heading3 thq-heading-3">
                    Exceptional Service:
                  </h3>
                  <p class="thq-body-small">
                    Our commitment to exceptional service extends not only to
                    our clients but also to our employees. We value work-life
                    balance, professional growth, and a supportive workplace.
                  </p>
                </li>
                <li class="list-item">
                  <h3 class="career-heading4 thq-heading-3">
                    Professional Development:
                  </h3>
                  <p class="thq-body-small">
                    ABAM OMAN SPC invests in the continuous development of our team
                    members. Whether it’s attending workshops, pursuing
                    certifications, or participating in industry conferences, we
                    encourage growth.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="career-container2 thq-section-padding">
          <div class="career-max-width1 thq-section-max-width">
            <div class="career-container3">
              <h2 class="career-title thq-heading-2">Join Us</h2>
              <h3 class="career-text thq-heading-3">
                Upload resume or mail us
              </h3>
              <div class="career-actions">
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLScoINCUJfKjrl8uGJc99CnRSOGFKYPtJ5hkKgfRJX8rZvrhyQ/viewform?usp=sf_link"
                  target="_blank"
                  rel="noreferrer noopener"
                  class="career-button thq-button-filled"
                >
                  <span class="career-text1 thq-body-small">Upload Resume</span>
                </a>
                <a
                  href="mailto:info@abamoman.com?subject=Career"
                  class="career-button1 thq-button-outline"
                >
                  <span class="career-text2 thq-body-small">Just Email Us</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer rootClassName="footer-root-class-name3"></Footer>
    </div>
  )
}

export default Career
