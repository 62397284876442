import React from "react";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import Footer from "../components/footer";
import "./clients.css";

const Clients = (props) => {
  return (
    <div className="mt-4 clients-container">
      <Helmet>
        <title>Clients</title>
        <meta
          name="description"
          content="Discover the prestigious clients of ABAM OMAN SPC and our successful partnerships in the electrical solutions industry."
        />
        <link rel="canonical" href="/clients" />
      </Helmet>
      {/* <Navbar rootClassName="navbar-root-class-name4"></Navbar> */}
      <section className="clients-news">
        <div className="clients-heading">
          <h2 className="clients-text">Our Clients</h2>
          <p className="clients-text1">Our Major Clients Include...</p>
        </div>
      </section>
      <div className="clients-features">
        <div className="clients-container1">
          <div className="clients-feature-card">
            <h2 className="clients-text2">Ministry of housing </h2>
            <img
              alt="image"
              src="/News/untitled-31-300h.jpg"
              className="clients-image"
            />
          </div>
          <div className="clients-feature-card1">
            <h2 className="clients-text3">Nama Electricity Distribution</h2>
            <img
              alt="image"
              src="/News/NamaLogo.png"
              className="clients-image1"
            />
          </div>
          
          <div className="clients-feature-card3">
            <h2 className="clients-text8">Ministry of education</h2>
            <img
              alt="image"
              src="/News/ministry_of_education_logo-300w.jpg"
              className="clients-image3"
            />
          </div>
        </div>
      </div>
      <Footer rootClassName="footer-root-class-name3"></Footer>
    </div>
  );
};

export default Clients;
