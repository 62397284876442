import { useState, useEffect } from "react";
import { useAuth } from "../context/Context";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Spinner from 'react-bootstrap/Spinner';

const Login = () => {
  const { login } = useAuth(); // Access the login function from the authentication context
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useHistory();
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      // Call the login function from the context with email and password
      await login(email, password);
      setLoading(false);
      // Redirect to a different route if login is successful
    } catch (error) {
      // Handle login error
      toast.error("login failed");
      console.error(error);
      setLoading(false);

    }
  };
  const { user } = useAuth();
  useEffect(() => {
    console.log("user" + user?.useName);
    if (user?.userName && user?.token) {
      navigate.push("/Admin/panel");
    }
  }, [user]);

  return (
    <div>
      <ToastContainer />
      <body>
        <div className="container">
          <div className="row">
            <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
              <div className="card border-0 shadow rounded-3 my-5">
                <div className="card-body p-4 p-sm-5">
                  <h5 className="card-title text-center mb-5 fw-light fs-5">
                    <img
                      src="/Branding/bes%20logo%20(1)-1-200h.png"
                      width="120"
                      height="60"
                      className="d-inline-block align-top"
                      alt="React Bootstrap logo"
                    />
                  </h5>
                  <form onSubmit={handleLogin}>
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        placeholder="name@example.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required/>
                      <label htmlFor="floatingInput">User Name</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        type="password"
                        className="form-control"
                        id="floatingPassword"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required/>
                      <label htmlFor="floatingPassword">Password</label>
                    </div>

                    <div className="d-grid">
                      {loading?(<Spinner className="mx-3" animation="grow" />):( <button
                        className="btn btn-primary btn-login text-uppercase fw-bold"
                        type="submit"
                      >
                        Log in
                      </button>)}
                     
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </div>
  );
};

export default Login;
