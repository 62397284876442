import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './news.css'

const News = (props) => {
  return (
    <section className={`news-news ${props.rootClassName} `}>
      <div className="news-heading">
        <h2 className="news-text">{props.heading}</h2>
        <p className="news-text1">{props.text}</p>
      </div>
      <div className="news-list">
        <Link to="/project-details" className="news-navlink">
          <div className="news-item">
            <div className="news-image">
              <img
                alt={props.imageAlt11}
                src={props.imageSrc11}
                className="news-image1"
              />
            </div>
            <div className="news-content">
              <div className="news-details">
                <span className="news-date">{props.date}</span>
                <p className="news-quick-description">
                  {props.quickDescription}
                </p>
              </div>
              <div className="read-more">
                <span className="news-text2">{props.text1}</span>
                <img
                  alt={props.imageAlt3}
                  src={props.imageSrc3}
                  className="news-image2"
                />
              </div>
            </div>
          </div>
        </Link>
        <Link to="/project-details" className="news-navlink1">
          <div className="news-item1">
            <div className="news-image3">
              <img
                alt={props.imageAlt1}
                src={props.imageSrc1}
                className="news-image4"
              />
            </div>
            <div className="news-content1">
              <div className="news-details1">
                <span className="news-date1">{props.date1}</span>
                <p className="news-quick-description1">
                  {props.quickDescription1}
                </p>
              </div>
              <div className="read-more">
                <span className="news-text3">{props.text2}</span>
                <img
                  alt={props.imageAlt4}
                  src={props.imageSrc4}
                  className="news-image5"
                />
              </div>
            </div>
          </div>
        </Link>
        <Link to="/project-details" className="news-navlink2">
          <div className="news-item2">
            <div className="news-image6">
              <img
                alt={props.imageAlt2}
                src={props.imageSrc2}
                className="news-image7"
              />
            </div>
            <div className="news-content2">
              <div className="news-details2">
                <span className="news-date2">{props.date2}</span>
                <p className="news-quick-description2">
                  {props.quickDescription2}
                </p>
              </div>
              <div className="read-more">
                <span className="news-text4">{props.text3}</span>
                <img
                  alt={props.imageAlt5}
                  src={props.imageSrc5}
                  className="news-image8"
                />
              </div>
            </div>
          </div>
        </Link>
      </div>
    </section>
  )
}

News.defaultProps = {
  imageSrc4: '/Icons/arrow-2.svg',
  imageAlt: 'image',
  quickDescription:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  imageSrc: '2c0669bd-2b94-4ff0-bcd5-41283f4ce60b',
  date: 'company name',
  imageSrc3: '/Icons/arrow.svg',
  imageSrc5: '/Icons/arrow-2.svg',
  text1: 'Read more',
  imageAlt1: 'image',
  heading: 'Ongoing Works',
  text3: 'Read more',
  text: 'List of Major Ongoing Works',
  date2: 'company name',
  text2: 'Read more',
  imageAlt4: 'image',
  imageAlt3: 'image',
  quickDescription2: 'dfasdfgsgsdg',
  imageAlt5: 'image',
  date1: 'company name',
  rootClassName: '',
  imageSrc2:
    'https://images.unsplash.com/photo-1581092334651-ddf26d9a09d0?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDE0fHxlbGVjdHJpY2FsJTIwZW5naW5lZXJ8ZW58MHx8fHwxNzA1NjQzNDEyfDA&ixlib=rb-4.0.3&w=1500',
  imageAlt2: 'image',
  quickDescription1:
    'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  imageSrc1:
    'https://images.unsplash.com/photo-1581091226825-a6a2a5aee158?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDN8fGVsZWN0cmljYWwlMjBlbmdpbmVlcnxlbnwwfHx8fDE3MDU2NDM0MTJ8MA&ixlib=rb-4.0.3&w=1500',
  imageAlt11: 'image',
  imageSrc11:
    'https://images.unsplash.com/photo-1581091226825-a6a2a5aee158?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDN8fGVsZWN0cmljYWwlMjBlbmdpbmVlcnxlbnwwfHx8fDE3MDU2NDM0MTJ8MA&ixlib=rb-4.0.3&w=1500',
}

News.propTypes = {
  imageSrc4: PropTypes.string,
  imageAlt: PropTypes.string,
  quickDescription: PropTypes.string,
  imageSrc: PropTypes.string,
  date: PropTypes.string,
  imageSrc3: PropTypes.string,
  imageSrc5: PropTypes.string,
  text1: PropTypes.string,
  imageAlt1: PropTypes.string,
  heading: PropTypes.string,
  text3: PropTypes.string,
  text: PropTypes.string,
  date2: PropTypes.string,
  text2: PropTypes.string,
  imageAlt4: PropTypes.string,
  imageAlt3: PropTypes.string,
  quickDescription2: PropTypes.string,
  imageAlt5: PropTypes.string,
  date1: PropTypes.string,
  rootClassName: PropTypes.string,
  imageSrc2: PropTypes.string,
  imageAlt2: PropTypes.string,
  quickDescription1: PropTypes.string,
  imageSrc1: PropTypes.string,
  imageAlt11: PropTypes.string,
  imageSrc11: PropTypes.string,
}

export default News
