import React, { useState,useEffect } from 'react';
import { Container, Row, Col, Nav, Modal, Image,Fade } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useAuth } from "../context/Context";
import { Urls } from "../context/Urls";

function Certificate () {
  const [show, setShow] = useState(false);
  const [selectedCert, setSelectedCert] = useState(null);
  const {getCertificate, certificates } = useAuth();
  const [heading, setHeading ] = useState("HSE Certificate");
  const [page, setPage] = useState('certificate');
  const [inProp, setInProp] = useState(true);

  useEffect(() => {
    setInProp(false)
    getCertificate(page);
    setTimeout(() => {
      setInProp(true);
    }, 500);
  }, [page]);

    const handleNavClick = (value) => {
      setPage(value)
      // getCertificate(value);
      console.log(`Nav.Link clicked: ${value}`);
      setTimeout(() => {
        if(value === 'certificate'){
          setHeading("HSE Certificate")
        }else if(value === 'policy'){
          setHeading("Policies");
        }
      }, 500);
   

      
      // Perform any action with the value, such as navigation or state update
    };

  const handleClose = () => setShow(false);
  const handleShow = (cert) => {
    
    setSelectedCert(cert);
    setShow(true);
  };

  return (
    <Container>
      <Nav variant="tabs" activeKey={page} >
        <Nav.Item>
          <Nav.Link style={{color:"black"}} onClick={() => handleNavClick('certificate')}>HSE Certificate</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link style={{color:"black"}} onClick={() => handleNavClick('policy')}>Policies</Nav.Link>
        </Nav.Item>
      </Nav>
      <Fade in={inProp}>
        <div>
      <h2 className="mt-3 pb-3">{heading}</h2>
      <Row>
        {certificates.map((cert) => (
          <Col key={cert.id} xs={12} md={6} lg={3} className="mb-4">
            <Image
              src={Urls.IMAGE_URL+cert.images}
              alt="HSE Certificate"
              thumbnail
              onClick={() => handleShow(cert)}
              style={{ cursor: 'pointer' }}
            />
          </Col>
        ))}
      </Row>
      </div>
      </Fade>
      <Modal show={show} onHide={handleClose} centered>
        
    
        <Modal.Body>
          {selectedCert && <Image src={Urls.IMAGE_URL+selectedCert.images} alt={selectedCert} fluid />}
        </Modal.Body>
       
      </Modal>
    </Container>
  );
}

export default Certificate ;
