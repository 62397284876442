import React from "react";
import Footer from "./footer";
import "./teams.css";

const Teams = () => {
  return (
<body>
    <section class="team-section">
        <div class="team-container">
            <h1 class="section-title">Our Leadership Team</h1>
            <p class="section-subtitle">Driving Excellence in Electrical Engineering Across Oman</p>
            
         
            <div class="featured-leader">
                <div class="featured-leader-image-container">
                    <img src="leader.png" alt="Anu Balachandran Unnithan" class="featured-leader-image"/>
                </div>
                <div class="featured-leader-info">
                    <h2 class="featured-leader-name">Anu Balachandran Unnithan</h2>
                    <p class="featured-leader-position">Managing Director and CEO</p>
                    <p class="featured-leader-quote">"AL BADR AL MOUNIR ENGINEERING AND CONTRACTING SPC, we power progress with precision and deliver excellence in every project, from underground cables to substations."</p>
                    <p>With extensive experience in electrical engineering and a proven track record of successful project delivery, Anu leads our company's vision of powering Oman's future through innovation and excellence. Under his leadership, AL BADR AL MOUNIR has established itself as a trusted partner for both government and private sector projects.</p>
                </div>
            </div>
            

            <div class="other-members">
                <div class="team-card">
                    <div class="team-image-container-small">
                        <img src="general manager.jpeg" alt="Mazin Hilal Saleem Alqasmi" class="team-image-small"/>
                    </div>
                    <div class="team-info-small">
                        <h3 class="team-name-small">Mazin Hilal Saleem Alqasmi</h3>
                        <p class="team-position-small">General Manager</p>
                        <p class="team-description">As General Manager, Mazin brings valuable local expertise and strategic leadership to our operations. With his deep understanding of Oman's electrical infrastructure requirements and strong project management skills, he ensures the successful execution of complex projects while maintaining the highest quality standards. His commitment to excellence and customer satisfaction has been instrumental in strengthening our position as a leading electrical engineering contractor in Oman.</p>
                    </div>
                </div>
            </div>
        </div>
        <Footer rootClassName="footer-root-class-name3"></Footer>

    </section>
</body>
  );
};

export default Teams;
