import React from 'react'

import PropTypes from 'prop-types'

import './footer.css'

const Footer = (props) => {
  return (
    <div className={`footer-footer ${props.rootClassName} `}>
      <div className="footer-left">
        <div className="footer-brand">
          <div className="footer-container">
            <img
              alt={props.imageAlt}
              src={props.imageSrc}
              className="footer-image"
            />
            <img
              src={props.imageSrc5}
              alt={props.imageAlt5}
              className="footer-image1"
            />
            <img
              src={props.imageSrc4}
              alt={props.imageAlt4}
              className="footer-image2"
            />
          </div>
          <p className="footer-text">{props.text}</p>
        </div>
        <div className="footer-socials">
          <div className="social">
            <img
              alt={props.imageAlt1}
              src={props.imageSrc1}
              className="footer-image3"
            />
          </div>
          <div className="social">
            <img
              alt={props.imageAlt2}
              src={props.imageSrc2}
              className="footer-image4"
            />
          </div>
          <div className="social">
            <img
              alt={props.imageAlt3}
              src={props.imageSrc3}
              className="footer-image5"
            />
          </div>
        </div>
        <div className="footer-legal">
          <span className="footer-copyright">{props.copyright1}</span>
          <span className="legal-link">{props.copyright2}</span>
          <span className="legal-link">{props.copyright3}</span>
        </div>
      </div>
      <div className="footer-right">
        <div className="footer-list"></div>
        <div className="footer-list1">
          <span className="footer-header">{props.header2}</span>
          <div className="footer-links">
            <span className="footer-link">{props.link5}</span>
            <a
              href="mailto:contact@template.new?subject=Main"
              className="footer-link1"
            >
              {props.link6}
            </a>
            <a href="tel:(004) 234 - 5678" className="footer-link2">
              {props.link7}
            </a>
          </div>
        </div>
      </div>
      <div className="footer-legal1">
        <div className="footer-row">
          <span className="legal-link">{props.copyright4}</span>
          <span className="legal-link">{props.copyright5}</span>
        </div>
        <span className="footer-copyright5">{props.copyright}</span>
      </div>
    </div>
  )
}

Footer.defaultProps = {
  rootClassName: '',
  link7: '+968-71160030',
  link2: 'Services',
  imageSrc2: '/Icons/instagram.svg',
  imageSrc3: '/Icons/twitter.svg',
  imageAlt3: 'image',
  copyright: '© 2022 finbest. All Rights Reserved.',
  link1: 'About',
  header1: 'Resources',
  imageSrc: '/Branding/logo-1-removebg-preview-200h.png',
  copyright4: 'Privacy Policy',
  link: 'Home',
  imageAlt: 'image',
  header2: 'Contact',
  header: 'Menu',
  imageSrc1: '/Icons/insider.svg',
  copyright5: 'Terms of Use',
  copyright1: '©ABAM OMAN SPC 2024 . All Rights Reserved.',
  link4: 'Support',
  link6: 'info@abamoman.com',
  imageAlt1: 'image',
  link5: 'C.R 1571977, P.O. Box: 320 Postal Code: 762 Sultanate of Oman',
  text: "Powering Oman's Future with Quality and Innovation",
  imageAlt2: 'image',

  link3: 'Blog',
  imageSrc4: '/News/flag_of_oman.svg-200h.png',
  imageAlt4: 'image',
  imageSrc5: '/News/flag-india-200h.webp',
  imageAlt5: 'image',
}

Footer.propTypes = {
  rootClassName: PropTypes.string,
  link7: PropTypes.string,
  link2: PropTypes.string,
  imageSrc2: PropTypes.string,
  imageSrc3: PropTypes.string,
  imageAlt3: PropTypes.string,
  copyright: PropTypes.string,
  link1: PropTypes.string,
  header1: PropTypes.string,
  imageSrc: PropTypes.string,
  copyright4: PropTypes.string,
  link: PropTypes.string,
  imageAlt: PropTypes.string,
  header2: PropTypes.string,
  header: PropTypes.string,
  imageSrc1: PropTypes.string,
  copyright5: PropTypes.string,
  copyright1: PropTypes.string,
  link4: PropTypes.string,
  link6: PropTypes.string,
  imageAlt1: PropTypes.string,
  link5: PropTypes.string,
  text: PropTypes.string,
  imageAlt2: PropTypes.string,
  copyright3: PropTypes.string,
  copyright2: PropTypes.string,
  link3: PropTypes.string,
  imageSrc4: PropTypes.string,
  imageAlt4: PropTypes.string,
  imageSrc5: PropTypes.string,
  imageAlt5: PropTypes.string,
}

export default Footer
