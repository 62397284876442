/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { Urls } from "./Urls";
import { toast } from "react-toastify";
const AuthContext = createContext();
import { useHistory } from "react-router-dom";

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [logoutTimer, setLogoutTimer] = useState(null);
  // const [phone, setPhone] = useState("");
  const [project, setProject] = useState([]);
  const [onGoingWorks, setonGoingWorks] = useState([]);
  const [certificates, setCertificates] = useState([]);

  const navigate = useHistory();

  useEffect(() => {
    const storedUserData = localStorage.getItem("userData");
    if (storedUserData) {
      const parsedUserData = JSON.parse(storedUserData);
      setUser(parsedUserData);
    }
  }, []);

  const login = async (userName, password) => {
    try {
      // Make an API call to your authentication endpoint
      console.log(userName, password);
      const response = await axios.post(Urls.BACKEND_URL + "/user/login", {
        userName,
        password,
      });
      console.log("response data", response.data);
      // Assuming your server returns user data upon successful login
      setUser(response.data);
      localStorage.setItem("userData", JSON.stringify(response.data));

      // Set a timer to automatically log the user out after 30 minutes
      const timer = setTimeout(logout, 30 * 60 * 1000); // 30 minutes
      setLogoutTimer(timer);
    } catch (error) {
      toast.error("Wrong usename or password");
      console.error("Login failed:", error);
    }
  };

  const getCertificate = async (Type)=>{
    setCertificates([])
    try{
    console.log(Type);
    const certificatesData = await axios.get(Urls.BACKEND_URL + "/certificate/getCertificates", {
      params: {
        type: Type // Specify your parameter name here
      }})
      console.log(certificatesData)
     setCertificates(certificatesData.data.data);
    }catch(e) {
      toast.error("error occured");
    }
  }
  const logout = () => {
    // Clear the user data and the logout timer
    localStorage.removeItem("userData");
    setUser(null);
    clearTimeout(logoutTimer);
    navigate.push("/Admin/login");
  };

  const addCertificate = async(Data)=>{
    const config = {
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
    };
  try{
    console.log("addCertificate", Data);
    const data = await axios.post(Urls.BACKEND_URL + "/certificate/addCertificates",Data,config);
    if(data.status ==200){
      toast.success("success")
    }else if(data.status ==400){
      toast.error("invalid input")
    }
  else if(data.status ==401){
    logout();
    navigate.push("/Admin/login");
  }
  }catch(e){
    console.log("error", e.response.status);
    if(e.response.status === 401){
      logout();
      navigate.push("/Admin/login")
    }
    toast.error(e.response.data.message)
  }
  getCertificate('certificate')
  }
  useEffect(() => {
    const getData = async () => {
      try {
        const projectsData = await axios.get(
          Urls.BACKEND_URL + "/data/getProjects"
        );
        const onGoingWorksData = await axios.get(
          Urls.BACKEND_URL + "/data/getOngoingWorks"
        );
     
        await setonGoingWorks(onGoingWorksData.data.data);
        await setProject(projectsData.data.data);
        console.log(onGoingWorksData.data.data);
        console.log(project);
        // console.log("Phone Number: " + phone.data);
      } catch (error) {
        if (error.response) {
          // console.log("error=" + error.response.status);
        } else {
          // console.log("Network or server error:", error.message);
        }
        console.log(error);
      }
    };
    getData();
  }, []);

  return (
    <AuthContext.Provider
      value={{ user, project, onGoingWorks, login, logout,getCertificate,certificates,addCertificate }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  return useContext(AuthContext);
};

export { useAuth };
